import classNames from 'classnames';
import React, { CSSProperties } from 'react';
import { TextFieldVariantProps as Props } from './Textfield.types';

export const TextFieldFilled: React.FC<Props> = ({
  className,
  fullWidth,
  color = '#E7E7E7',
  style,
  inputClassName,
  ...props
}) => {
  const styles: CSSProperties = {
    ...style,
    backgroundColor: color,
    borderColor: color,
  };

  return (
    <input
      className={classNames(inputClassName, 'border border-gray-300 rounded-lg', className, {
        'w-full': fullWidth,
      })}
      style={styles}
      {...props}
    />
  );
};
