import classNames from 'classnames';
import React from 'react';
import { ButtonVariantProps } from '../Button.types';

export const APPButtonContained: React.FC<ButtonVariantProps> = ({ children, className, ...rest }) => (
  <button
    className={classNames('text-white', className)}
    {...rest}
  >
    {children}
  </button>
);
