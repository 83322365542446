export interface OAuthErrorResponse {
  error: string;
  errorDescription?: string;
  requestId?: string;
}

export interface ApiErrorResponse {
  statusCode: number;
  error: string;
  message: string;
}

export const isOAuthErrorResponse = (errorResponse: unknown):
  errorResponse is OAuthErrorResponse => typeof errorResponse === 'object'
    && 'error' in errorResponse;

export const isApiErrorResponse = (errorResponse: unknown)
: errorResponse is ApiErrorResponse => typeof errorResponse === 'object'
  && errorResponse !== null
  && 'statusCode' in errorResponse
  && 'error' in errorResponse
  && 'message' in errorResponse
  // eslint-disable-next-line dot-notation
  && typeof errorResponse['statusCode'] === 'number'
  // eslint-disable-next-line dot-notation
  && typeof errorResponse['error'] === 'string'
  // eslint-disable-next-line dot-notation
  && typeof errorResponse['message'] === 'string';
