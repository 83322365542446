import classNames from 'classnames';
import React from 'react';
import { TextFieldFilled } from './Textfield.filled';
import { TextFieldOutline } from './Textfield.outline';
import { TextFieldStandard } from './Textfield.standard';
import { BaseTextFieldProps as Props } from './Textfield.types';

export const BASE_CLASSES = 'border-none outline-none p-3 text-base w-full appearance-none text-darkGrey text-center';

export const TextField: React.FC<Props> = ({
  variant = 'standard',
  inputClassName,
  disabled,
  ...props
}) => {
  const customStyles = {
    'opacity-50': disabled,
  };

  return ({
    standard: (
      <TextFieldStandard
        inputClassName={classNames(BASE_CLASSES, customStyles, inputClassName)}
        {...props}
      />
    ),
    outlined: (
      <TextFieldOutline
        inputClassName={classNames(BASE_CLASSES, customStyles, inputClassName)}
        {...props}
      />
    ),
    filled: (
      <TextFieldFilled
        inputClassName={classNames(BASE_CLASSES, customStyles, inputClassName)}
        {...props}
      />
    ),
  }[variant]);
};
