import React from 'react';
import classNames from 'classnames';
import { TextFieldVariantProps as Props } from './Textfield.types';

export const TextFieldStandard: React.FC<Props> = ({
  className,
  fullWidth,
  inputClassName,
  ...props
}) => (
  <input
    className={classNames(inputClassName, className, 'border-b border-gray-300', {
      'w-full': fullWidth,
    })}
    {...props}
  />
);
