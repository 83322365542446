import classNames from 'classnames';
import React from 'react';
import { ButtonProps } from '../Button.types';
import { APPButtonContained } from './Button.contained';
import { APPButtonOutlined } from './Button.outlined';
import { APPButtonText } from './Button.text';

const BASE_CLASSES = 'box-border focus:outline-none flex justify-around text-center p-2.5 rounded-lg border-2';

export const APPButton: React.FC<ButtonProps> = ({
  variant,
  className,
  ...rest
}) => ({
  contained: <APPButtonContained className={classNames(BASE_CLASSES, className)} {...rest} />,
  outlined: <APPButtonOutlined className={classNames(BASE_CLASSES, className)} {...rest} />,
  text: <APPButtonText className={classNames(BASE_CLASSES, className)} {...rest} />,
}[variant]);
