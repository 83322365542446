import classNames from 'classnames';
import React from 'react';
import { TextFieldVariantProps as Props } from './Textfield.types';

export const TextFieldOutline: React.FC<Props> = ({
  className,
  fullWidth,
  inputClassName,
  ...props
}) => (
  <input
    className={classNames(inputClassName, className, 'border border-gray-300 rounded-lg', {
      'w-full': fullWidth,
    })}
    {...props}
  />
);
