import { useRouter } from 'next/router';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';
import { LayoutProps as Props } from './Layout.types';
import DefaultLogo from '../../../public/default-logo.png';

const APP_VERSION = process.env.NEXT_PUBLIC_VERSION;

export const Layout: React.FC<Props> = ({
  children,
  footer: Footer,
}) => {
  const router = useRouter();
  const queryParams = router.query;
  const { state: searchStateParam } = queryParams;
  const authStateParam = Array.isArray(searchStateParam) ? searchStateParam[0] : searchStateParam;

  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    const registrationPages = ['/register', '/confirm-register'];
    const isRegisterPage = registrationPages.includes(router.pathname);

    const isErrorPage = router.pathname === '/error';
    if (authStateParam || isRegisterPage || isErrorPage || !router.isReady) return;
    setErrorMessage('Invalid Request. State must be set!');
  }, [router.isReady, authStateParam]);

  return (
    <div className='flex items-center justify-center w-full h-full overflow-y-auto bg-gray-200'>
      <div className='w-full h-full max-w-3xl p-6 mx-auto sm:h-auto'>
        <div className='w-full h-20 max-w-sm mx-auto mb-5 md:w-3/4 md:w-90 sm:h-28'>
          <Image src={DefaultLogo} />
        </div>
        <div className='flex flex-col justify-between w-full p-6 pb-3 bg-white rounded-lg shadow-lg min-h-560 text-darkGrey'>
          <div className='mx-0 sm:mx-10'>
            {children}
            <p className='text-center text-red-600'>{errorMessage}</p>
          </div>

          {Footer}
        </div>
        {APP_VERSION && (
          <div className='w-full mt-5 text-xs font-normal text-right text-gray-400'>
            <p className='mr-7'>Ver. {APP_VERSION}</p>
          </div>
        )}
      </div>
    </div>
  );
};
