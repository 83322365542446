import classNames from 'classnames';
import React from 'react';
import { APPButton } from './app/Button';
import { BaseButtonProps } from './Button.types';

export const Button: React.FC<BaseButtonProps> = ({
  variant = 'text',
  fullWidth = false,
  disabled = false,
  className,
  ...props
}) => (
  <APPButton
    className={classNames(
      className,
      { 'w-full': fullWidth },
      { 'opacity-50 cursor-not-allowed': disabled },
    )}
    variant={variant}
    disabled={disabled}
    fullWidth={fullWidth}
    {...props}
  />
);
