import React from 'react';
import classNames from 'classnames';
import { HelpLinksProps as Props } from './HelpLinks.types';
import { DEFAULT_HELP_LINKS } from '../../utils/constants';

export const HelpLinks: React.FC<Props> = ({ className, items = DEFAULT_HELP_LINKS }) => (
  <div className={classNames('flex justify-between w-full md:w-auto', className)}>
    {items.map(({ href, text, target = '_blank' }, index) => (
      <a
        key={index}
        className='p-2 text-sm font-normal underline'
        target={target}
        rel='noopener noreferrer'
        href={href}
      >
        {text}
      </a>
    ))}
  </div>
);
